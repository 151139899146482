import { config } from './../../config/globals';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentTenantId = new BehaviorSubject('');

  clientId: string;
  redirectUri: string;
  codeChallenge: string;
  appUrl: string;
  codeVerifier = 'HUp88gHhCejXq1.Hq1l4q1.SgsER7h9d6aZEMbF6qOTKyMmmlx_ZlrD~M0W1RjrrAlNXviMmsucZxZg.qLCzYRZ9BXPJ0Mi.l6EVIyBMwECIyEwwnYXi75zg9NF7veA0';

  constructor(private http: HttpClient, private router: Router) {
    this.clientId = '787E30007EF645089F3E2F0C90A377F8';
    this.redirectUri = config.application.url.getAppUrl() + '/login';

    this.codeChallenge = 'V1lNEg4RZtqDQd0Q2QHx6dDXOXNHeB855y3xGPafk5A';
    this.appUrl = config.application.url.getAppUrl() + '/login';

  }

  redirectToLogin() {
    // tslint:disable-next-line: max-line-length
    const login = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&scope=openid profile email accounting.transactions accounting.contacts.read&state=12&code_challenge=${this.codeChallenge}&code_challenge_method=S256`;
    window.location.href = login;
  }

  getToken(code: string) {

    const formData = new FormData();
    formData.append('grant_type', 'authorization_code');
    formData.append('client_id', this.clientId);
    formData.append('code', code);
    formData.append('redirect_uri', config.application.url.getAppUrl() + '/login');
    formData.append('code_verifier', this.codeVerifier);

    // tslint:disable-next-line: max-line-length
    this.http.post<{id_token: string, access_token: string, expires_in: number}>('https://identity.xero.com/connect/token', formData).subscribe((response) => {
      console.log(response);

      localStorage.setItem('id_token', response.id_token);
      localStorage.setItem('access_token', response.access_token);

      const expiry = new Date().getTime() + response.expires_in + (1800 * 1000);
      localStorage.setItem('expiry', String(expiry));
      const helper = new JwtHelperService();
      const data = helper.decodeToken(response.access_token);

      this.getConnections(data.authentication_event_id, response.access_token);

      this.router.navigate(['/app']);

    }, (err) => {
      this.router.navigate(['/']);
    });
  }

  getConnections(authEventId: string, token: string) {
    this.http.get<any>(config.api.getBaseUrl() + '/xero/connections', {
      headers: {
        authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      }
    })
    .subscribe(r => {
      const tenant = r.filter(x => x.authEventId === authEventId);
      console.log(tenant);
      this.currentTenantId.next(tenant[0].tenantId);
      localStorage.setItem('tenantId', tenant[0].tenantId);
    });
  }



  getTenantId() {
    return localStorage.getItem('tenantId');
  }

}
