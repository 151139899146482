import { CreditNote } from './../../models/creditNote';
import { config } from './../../config/globals';
import { getTestBed } from '@angular/core/testing';
import { XeroService } from './../xero/xero.service';
import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { Contact } from 'src/app/models/contact';
import { Invoice, LineItem } from 'src/app/models/invoice.model';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { resolve } from 'url';
import { XeroResponse } from 'src/app/interfaces/XeroInvoiceResponse';


@Injectable({
  providedIn: 'root'
})
export class ConverterService {

  contacts: Contact[];

  constructor(private parser: NgxCsvParser, private http: HttpClient, private auth: AuthService, private xero: XeroService) { }

  parseCsv(file: File) {
    return new Promise((resolve, reject) => {
      this.parser.parse(file, { header: true, delimiter: ',' })
        .pipe().subscribe((result: Array<any>) => {

          this.convertChalkStringToXero(result)
            .then((data) => {
              resolve(data);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        }, (error: NgxCSVParserError) => {
          console.log('Error', error);
          reject(error);
        });
    });
  }

  submitToXero(data: any) {
    return new Promise((resolve, reject) => {

      // Only submit invoices if there are any.
      let invs;
      if (data.invoices.length > 0) {
        invs = this.xero.submitInvoices(data.invoices);
      }


      let cns;
      // Only submit to xero if there are any.
      if (data.creditNotes.length > 0) {
        cns = this.xero.submitCreditNotes(data.creditNotes);
      }

      // Wait for all requests to finish.
      Promise.all([invs, cns])
        .then((res) => {
          console.log(res);
          resolve(res);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  convertChalkStringToXero = (rows: any[]): Promise<{ invoices: Invoice[]; creditNotes: CreditNote[] }> => {
    return new Promise((resolve, reject) => {

      // Load contacts into memory.
      this.loadContacts()
        .then(() => {

          const usedCodes: string[] = [];
          const invoices: Invoice[] = [];
          const creditNotes: CreditNote[] = [];

          rows.forEach((row) => {
            /**
             * Process Invoices.
             */
            if (
              !(usedCodes.includes(row['Invoice Reference']))
              && (row['Transaction Type'] === 'PI' || row['Transaction Type'] === 'SI')
              && !(row['Invoice Reference'].substr(row['Invoice Reference'].length - 3) === 'CIS')
            ) {
              const date = moment(row.Date, 'DD-MM-YYYY').format('YYYY-MM-DD');

              // get contact ID
              const contactId = this.getContact(row['Payee Account Reference']);

              if (!contactId) {
                const message = `Identifier: ${row.Identifier} - No corresponding contact in Xero.`;
                return reject(message);
              }

              const i: Invoice = {
                Contact: {
                  ContactID: contactId
                },
                Type: this.invoiceType(row['Transaction Type']),
                Date: date,
                DueDate: moment(date).add(1, 'month').endOf('month'),
                InvoiceNumber: row['Invoice Reference'],
                LineItems: this.createLineItems(row['Invoice Reference'], rows)
              };

              usedCodes.push(row['Invoice Reference']);
              invoices.push(i);

            }

            if (!(usedCodes.includes(row['Invoice Reference'])) && ((row['Transaction Type'] === 'PC') || (row['Transaction Type'] === 'SC'))) {

              const contactId = this.getContact(row['Payee Account Reference']);

              if (!contactId) {
                const message = `Identifier: ${row.Identifier} - No corresponding contact in Xero.`;
                console.log(message);
                return reject(message);
              }

              const date = moment(row.Date, 'DD-MM-YYYY').format('YYYY-MM-DD');


              const creditNote: CreditNote = {
                Type: this.getCreditNoteType(row['Transaction Type']), // Get credit note type.
                Contact: {
                  ContactID: contactId,
                },
                Date: date,
                LineItems: this.createLineItems(row['Invoice Reference'], rows),
                CreditNoteNumber: row['Invoice Reference']
              };

              creditNotes.push(creditNote);
            }
          });
          resolve({ invoices, creditNotes });
        })
        .catch((err) => reject(err));

    });

  }


  invoiceType = (transactionType: string): string => {
    if (transactionType === 'PI') {
      return 'ACCPAY';
    } else if (transactionType === 'SI') {
      return 'ACCREC';
    }
  }

  getCreditNoteType = (transactionType: string): string => {
    if (transactionType === 'PC') {
      return 'ACCPAYCREDIT';
    } else {
      return 'ACCRECCREDIT';
    }
  }

  /**
   *
   * @param transactionType
   * @param vatValue
   * @param contactCode
   */
  taxTypeV2 = (transactionType: string, vatValue: number, contactCode: string): string => {
    if (transactionType === 'SI') {
      const code = this.contacts.filter(c => c.AccountNumber === contactCode)[0].AccountsReceivableTaxType;
      return code
      // return 'OUTPUT2';
    } else {
      const code = this.contacts.filter(c => c.AccountNumber === contactCode)[0].AccountsPayableTaxType;
      return code;
    }
  }
    /**
   * Returns the Tax Type
   * @param transactionType
   * @param vatValue
   * @param contactCode
   * @returns
   * @deprecated
   */
  taxType = (transactionType: string, vatValue: number, contactCode: string): string => {
    if (Number(vatValue) === 0) {
      const code = this.contacts.filter(c => c.AccountNumber === contactCode)[0].AccountsPayableTaxType;
      console.log(code)
      if (!code) {
          return 'NONE'
        }
    } else {
      if (transactionType === 'SI') {
        const code = this.contacts.filter(c => c.AccountNumber === contactCode)[0].AccountsReceivableTaxType;
        if (!code) {
          return 'NONE'
        }
        return code
        // return 'OUTPUT2';
      } else {
        const code = this.contacts.filter(c => c.AccountNumber === contactCode)[0].AccountsPayableTaxType;
        console.log(code)
        if (!code) {
          return 'INPUT2'
        }
        return code;
        // return 'INPUT2';
      }
    }
  }


  getContact = (accountNumber: string): any => {

    let an;
    const contacts = this.contacts;
    const contact = contacts.filter(x => x.AccountNumber === accountNumber);
    try {
      if (contact) {
        an = contact[0].ContactID;
      } else {
        an = null;
      }
      return an;
    } catch {
      return '';
    }

  }

  loadContacts = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.http.get<any>( config.api.getBaseUrl() + '/xero/contacts', {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'xero-tenant-id': this.auth.getTenantId(),
        }
      })
        .subscribe(response => {
          this.contacts = response.Contacts;
          resolve(response.Contacts);
        }, (err) => {
          console.log('ERROR')
          console.log('ERROR', err)
          reject(err);
        });

    });
  }

  createLineItems = (invoiceRef: string, rows: any[]): LineItem[] => {

    const rowsInInvoice = rows.filter(row => row['Invoice Reference'] === invoiceRef);

    const lineItems: LineItem[] = [];

    rowsInInvoice.forEach(row => {

      let ItemCode;
      let NominalCode = row['Nominal code'];

      // Deal with the item code...
      if (String(row['Nominal code'])[0] === '6') {
        ItemCode = row['Nominal code'];
        NominalCode = 320;

        // Look for CIS Reference
        const invoiceReference = row['Invoice Reference'];
        const matchString = 'CSA' + invoiceReference.match(/(\d+)/)[0] + 'CIS';
        const CISEntry = rows.filter(x => x['Invoice Reference'] === matchString);
        if (CISEntry.length === 1) {
          NominalCode = 321;
        }
      }

      // 11/11/21 - This is a hack to deal with the fact that the Nominal code is not always correct.
      // Amend incoming application to labour nominal code 321
      if (row['Transaction Type'] === 'PI' && String(row['Nominal code'])[0] == '4') {
        NominalCode = 321;
      }

      const lineItem = {
        ItemCode,
        Description: row.Identifier + '-' + row.Description,
        Quantity: 1,
        UnitAmount: row['Net Value'],
        AccountCode: NominalCode,
        TaxAmount: Number(row['VAT Value']),
        TaxType: this.taxTypeV2(row['Transaction Type'], row['VAT Value'], row['Payee Account Reference']),
      };

      lineItems.push(lineItem);
    });


    return lineItems;



  }

}
