import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { config } from '../../config/globals';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public version: string;

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.version = config.version;
  }

  login() {
    this.auth.redirectToLogin();
  }

}
