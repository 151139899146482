import { ConverterService } from './../../services/converter/converter.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import anime from 'animejs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { config } from '../../config/globals';
import { XeroInvoiceResponse, XeroResponse } from 'src/app/interfaces/XeroInvoiceResponse';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('fileSelection', { static: true }) fileSelector: ElementRef;

  fileToUpload: File = null;
  errors = [];
  loading = false;
  convertLoading = false;
  err: any;

  invoiceResponse: XeroResponse;
  invoiceErrors: XeroInvoiceResponse[];

  public invoices: any;

  data: any;
  public version = config.version;

  constructor(private converter: ConverterService, private snackbar: MatSnackBar) { }

  ngOnInit() {

  }

  openFileSelection() {
    this.fileSelector.nativeElement.click();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.convertFile();
  }

  convertFile() {
    this.convertLoading = true;
    this.converter.parseCsv(this.fileToUpload)
      .then((data: { invoices: any, creditNotes: any}) => {
        this.data = data;
        this.invoices = JSON.parse(JSON.stringify(data.invoices));
        // const newData = data.invoices.push(data.creditNotes);
        // this.dataSource.data.push(newData);
        this.err = null;
        this.convertLoading = false;
      })
      .catch((err) => {
        console.log(err);
        this.err = {detail: err};
        this.convertLoading = false;
      });
  }


  submitFiles() {
    this.loading = true;
    console.log(this.data);
    this.converter.submitToXero(this.data)
      .then((response: XeroResponse) => {
        this.loading = false;
        this.fileToUpload = null;
        this.data = null;
        console.log(response)

        this.invoiceResponse = response[0];
        this.invoiceErrors = response[0].Invoices.filter(invoice => invoice.HasErrors == true);

        console.log(this.invoiceErrors);

        this.successAnimation();
        this.successNotification();
      })
      .catch((err) => {
        console.log(err)
        this.err = { title: err.error.Title, detail: err.error.Detail};
        this.loading = false;
      })
  }

  successNotification() {
    this.snackbar.open('Successfully uploaded batch file.', 'Dismiss', {
      duration: 3000,
    });
  }

  successAnimation = (): void => {
    anime({
      targets: '#selectFileButton',
      keyframes: [
        {
          backgroundColor: '#44bd32',
          borderColor: '#44bd32',
        },
        {
          backgroundColor: '#0275d8',
          borderColor: '#0275d8',
        }
      ],
      duration: 2000,
      easing: 'easeOutElastic(1, .8)',
      loop: false,

    });

    const checkTimeLine = anime.timeline({
      autoplay: true,
      direction: 'alternate',
      loop: false
    });

    // add events to timeline
    checkTimeLine
      .add({
        targets: '.checkmark',
        fillOpacity: 1,
        scale: [
          { value: [0, 1], duration: 800, easing: 'easeOutQuad' }
        ]
      })
      .add({
        targets: '#check',
        strokeDashoffset: {
          value: [anime.setDashoffset, 0]
        },
        duration: 600,
        delay: 0,
        endDelay: 1000,
        easing: 'easeOutQuad'
      })
      .add({
        targets: '.checkmark',
        delay: 500,
      })
      .add({
        targets: '#button-text',
        delay: 2000,
        begin: () => {
          document.getElementById('check').style.display = 'none';
          document.getElementById('button-text').style.display = 'block';
        }
      });
  }


  errorAnimation = (): void => {
    anime({
      targets: '#selectFileButton',
      keyframes: [
        {
          backgroundColor: '#c23616',
          borderColor: '#c23616',
        },
        {
          backgroundColor: '#0275d8',
          borderColor: '#0275d8',
        }
      ],
      duration: 2000,
      easing: 'easeOutElastic(1, .8)',
      loop: false,

    });

    const checkTimeLine = anime.timeline({
      autoplay: true,
      direction: 'alternate',
      loop: false
    });
  }


}
