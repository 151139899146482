import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { ConverterService } from "src/app/services/converter/converter.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

interface LineItem {
  description: string;
  quantity: number;
}

@Component({
  selector: "app-invoice-table",
  templateUrl: "./invoice-table.component.html",
  styleUrls: ["./invoice-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class InvoiceTableComponent implements OnInit {
  @Input() private invoices: any;
  private contacts: any;

  loading = true;

  public dataSource: MatTableDataSource<any>;

  public columnsToDisplay = [
    "InvoiceNumber",
    "Contact",
    "Type",
    "Date",
    "LineItems",
  ];

  LIdisplayedColumns = [
    "Description",
    "Quantity",
    "UnitAmount",
    "AmountCode",
    "TaxAmount",
    "TaxType"
  ];

  expandedElement: LineItem | null;

  constructor(private converterService: ConverterService) {}

  async ngOnInit() {
    // console.log(this.invoices);
    // // Get contacts
    this.contacts = await this.converterService.loadContacts();

    const invoicesForTable = this.invoices;

    // Parse invoices to add contacts
    const processedInvoices = invoicesForTable.map((invoice) => {
      // Get contact details by id
      invoice.Contact = this.contacts.filter((contact) => {
        return contact.ContactID == invoice.Contact.ContactID;
      })[0].Name;

      // Turn line items to table ddata
      // invoice.lineItems = new MatTableDataSource();
      invoice.lineItems = invoice.LineItems;
      // console.log(invoice.lineItems);

      return invoice;
    });

    this.dataSource = new MatTableDataSource();
    this.dataSource.data = processedInvoices;

    this.loading = false;
  }

  parseInvoiceType(type: string) {
    switch (type) {
      case "ACCPAY":
        return "PO";
      case "ACCREC":
        return "Invoice";
      default:
        return "Unknown";
    }
  }
}
