import { ENV } from './ENV';
export const config = {
  version: '1.1.0',
  api: {
    dev: 'https://api.sciltd.org.uk',//'http://localhost:3005',
    prod: 'https://api.sciltd.org.uk',
    getBaseUrl() {
      switch (ENV) {
        case 'DEV':
          return this.dev;
        case 'PROD':
          return this.prod;
        default:
          return '';
      }
    }
  },
  application: {
    url: {
      prod: 'https://application.chalkstring-connect.co.uk',
      dev: 'http://localhost:4200',
      getAppUrl() {
        switch (ENV) {
          case 'DEV':
            return this.dev;
          case 'PROD':
            return this.prod;
          default:
            return '';
        }
      }
    }
  }
};

