import { config } from './../../config/globals';
import { CreditNote } from './../../models/creditNote';
import { Invoice } from './../../models/invoice.model';
import { AuthService } from './../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class XeroService {

  headers: any;

  constructor(private http: HttpClient, private auth: AuthService) {

    this.headers = {
      authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'xero-tenant-id': this.auth.getTenantId(),
    };
  }

  submitInvoices(invoices: Invoice[]) {
    return new Promise((resolve, reject) => {
      this.http.post(config.api.getBaseUrl() + '/xero/invoices', { Invoices: invoices }, {
        headers: this.headers
      })
        .subscribe(r => resolve(r), e => reject(e));
    });
  }

  submitCreditNotes(creditNotes: CreditNote[]) {
    return new Promise((resolve, reject) => {
      this.http.post(config.api.getBaseUrl() + '/xero/creditNotes', { CreditNotes: creditNotes }, {
        headers: this.headers
      })
        .subscribe(r => resolve(r), e => reject(e));
    });
  }
}
